import {
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import TitleSection from "./TitleSection"

export interface FAQItem {
  question: string
  answer: JSX.Element | string
}

type FAQProps = {
  content: FAQItem[]
}

const FAQ = ({ content }: FAQProps): JSX.Element | null => {
  if (!content) {
    return null
  }

  return (
    <TitleSection
      style={{ backgroundColor: "backgroundSecondary.main" }}
      title="FAQ"
    >
      {content.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>{item.answer}</AccordionDetails>
        </Accordion>
      ))}
    </TitleSection>
  )
}

export default FAQ
