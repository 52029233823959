import React, { useEffect } from "react"
import { styled } from "@mui/material/styles"
import Rating from "@mui/material/Rating"
import Tooltip from "@mui/material/Tooltip"
import CheckIcon from "@mui/icons-material/CheckCircle"
import { format } from "date-fns"
import RoundedCard from "./RoundedCard"

interface ReviewProps {
  text: string
  name: string
  rating?: number
  location?: string
  date?: Date
}

const bluntSanitize = (text: string): string => (text.includes("@") ? "" : text)

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconFilled": {
    color: theme.palette.accentOne.main,
  },
}))

const Review = ({
  text,
  name,
  rating,
  date,
  location,
}: ReviewProps): JSX.Element => {
  useEffect(() => {
    const schema = {
      "@context": "http://schema.org",
      "@type": "Review",
      reviewBody: bluntSanitize(text),
      author: {
        "@type": "Person",
        name: bluntSanitize(name),
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: rating,
      },
      itemReviewed: {
        "@type": "Thing",
        name: "Driving Lessons",
      },
      datePublished: date ? format(date, "yyyy-MM-dd") : undefined,
      publisher: {
        "@type": "Organization",
        name: "Coastline Academy",
      },
    }

    const script = document.createElement("script")
    script.type = "application/ld+json"
    script.innerHTML = JSON.stringify(schema)
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [text, name, rating, date])

  return (
    <RoundedCard>
      {rating && (
        <div style={{ textAlign: "center" }}>
          <StyledRating value={rating} readOnly precision={0.5} />
        </div>
      )}
      {bluntSanitize(text)}
      <cite>
        <div
          style={{
            display: "flex",
            fontSize: 12,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 8,
            color: "#555",
          }}
        >
          <Tooltip title="Verified Customer">
            <CheckIcon fontSize="small" />
          </Tooltip>
          <div style={{ paddingLeft: 4 }}>
            {bluntSanitize(name)}
            {location && <>, {location}</>}
            {date && <>, {format(date, "PPPP")}</>}
          </div>
        </div>
      </cite>
    </RoundedCard>
  )
}

export default Review
