import { Button, ButtonGroup, useMediaQuery, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { useAnalytics } from "components/useAnalytics"

import HowToGetLicenseView from "./HowToGetLicenseView"
import { LICENSING_STEPS } from "./HowToGetLicenseContent"
import StateSelect from "./StateSelect"

type Props = {
  usState?: string
  path?: string
  highlightId?: string
  controlsToShow?: Array<"path" | "state">
  articleLinkColor?: string
}

function getPathOrDefault(usState?: string, path?: string): string | undefined {
  if (path) return path

  if (usState && !!LICENSING_STEPS[usState]) {
    return Object.keys(LICENSING_STEPS[usState])[0]
  }

  return undefined
}

const getStateOrUndefined = (usState?: string): string | undefined => {
  if (usState && !!LICENSING_STEPS[usState]) {
    return usState
  }

  return undefined
}

const HowToGetLicense = ({
  usState,
  path,
  highlightId,
  controlsToShow,
  articleLinkColor,
}: Props): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [chosenState, setChosenState] = React.useState<string | undefined>(
    getStateOrUndefined(usState),
  )
  const [chosenPath, setChosenPath] = React.useState<string | undefined>(
    getPathOrDefault(usState, path),
  )
  const { track } = useAnalytics()

  const handleSelectState = (state: string | undefined): void => {
    if (!state) return
    track("State Selected in Licensing Section", { state })
    setChosenPath(Object.keys(LICENSING_STEPS[state.toLowerCase()])[0])
    setChosenState(state.toLowerCase())
  }

  const controlComponents = controlsToShow
    ? controlsToShow.map((control) => {
        switch (control) {
          case "state":
            if (isMobile) {
              return (
                <Box sx={{ mb: 2 }}>
                  <StateSelect
                    setSelected={(state) => handleSelectState(state)}
                    selected={chosenState}
                  />
                </Box>
              )
            }
            return (
              <ButtonGroup
                sx={{ mr: 2, mb: 1, backgroundColor: "#fff" }}
                key="state"
                data-cy="state-button-group"
              >
                {Object.keys(LICENSING_STEPS).map((state) => (
                  <Button
                    key={state}
                    variant={chosenState === state ? "contained" : "outlined"}
                    onClick={() => handleSelectState(state)}
                  >
                    {state.toUpperCase()}
                  </Button>
                ))}
              </ButtonGroup>
            )
          case "path":
            return chosenState ? (
              <ButtonGroup
                sx={{ background: "#fff" }}
                key="path"
                data-cy="path-button-group"
              >
                {Object.keys(LICENSING_STEPS[chosenState]).map((pathId) => (
                  <Button
                    key={pathId}
                    variant={chosenPath === pathId ? "contained" : "outlined"}
                    onClick={() => {
                      track("Path Selected in Licensing Section", { pathId })
                      setChosenPath(pathId)
                    }}
                  >
                    {LICENSING_STEPS[chosenState][pathId].label}
                  </Button>
                ))}
              </ButtonGroup>
            ) : null
          default:
            return null
        }
      })
    : []

  return (
    <>
      {controlComponents.length > 0 ? (
        <Box sx={{ mb: 2, textAlign: "center" }}>
          {!chosenState && (
            <Box sx={{ mb: 2 }}>Choose your state to get started:</Box>
          )}
          {controlComponents}
        </Box>
      ) : null}
      {chosenState && chosenPath ? (
        <HowToGetLicenseView
          steps={LICENSING_STEPS[chosenState][chosenPath].steps}
          highlightId={highlightId}
          articleLink={LICENSING_STEPS[chosenState][chosenPath].article}
          articleLinkColor={articleLinkColor}
        />
      ) : null}
    </>
  )
}

export default HowToGetLicense
