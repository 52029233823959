import Link from "next/link"
import RoundedCard from "./RoundedCard"
import Prismic from "prismic-javascript"
import { RichText } from "prismic-reactjs"
import React, { useState, useEffect } from "react"
import { useTheme } from "@mui/material/styles"
import { Box, Typography, Grid } from "@mui/material"

const PRISMIC_URL = "https://driversed.cdn.prismic.io/api/v2"

const client = Prismic.client(PRISMIC_URL)

interface Category {
  slug: string
  data: {
    category: string
  }
}
interface CategoryObj {
  data: {
    category: string
  }
}

const renderCategory = (category: Category): JSX.Element => (
  <Link href={`/articles/${category.slug}`}>
    <span>{category.data.category}</span>
  </Link>
)

interface Article {
  id: string
  uid: string
  data: {
    title: any
    category: Category
  }
}
interface ArticlesProps {
  category?: string
  exclude?: string
  titlePrefix?: string
  limit?: number
  tag?: string
}

const Articles: React.FC<ArticlesProps> = ({
  category,
  exclude,
  titlePrefix = "All",
  limit = 50,
  tag,
}) => {
  const [articles, setArticles] = useState<Article[] | null>(null)
  const [categoryObj, setCategory] = useState<CategoryObj | null>(null)
  const theme = useTheme()

  useEffect(() => {
    const fetchPrismicData = async (): Promise<void> => {
      const predicates = [Prismic.Predicates.at("document.type", "article")]

      if (tag) {
        predicates.push(Prismic.Predicates.at("document.tags", [tag]))
      } else if (category) {
        // eslint-disable-next-line
        const categoryDoc = await client.getByUID(
          "article_category",
          category,
          {},
        )

        if (categoryDoc) {
          setCategory(categoryDoc as CategoryObj)
          predicates.push(
            Prismic.Predicates.at("my.article.category", categoryDoc.id),
          )
        }
      }

      const doc = await client.query(predicates, {
        orderings: "[my.article.date desc]",
        fetchLinks: "article_category.category",
        pageSize: limit,
      })

      if (doc) {
        setArticles(doc.results as Article[])
      }
    }
    fetchPrismicData()
  }, [category, limit, tag])

  if (!articles) return null

  const hasCategory = category && categoryObj

  return (
    <Grid item md={6} sm={12}>
      <RoundedCard
        style={{
          borderTop: `${theme.palette.secondary.main} 4px solid`,
        }}
      >
        <Box sx={{ mx: "auto", maxWidth: "800px" }}>
          {hasCategory ? (
            <Link
              href="/articles"
              style={{ color: theme.palette.primary.main }}
            >
              <span style={{ fontSize: 12 }}>&laquo; All Articles</span>
            </Link>
          ) : null}
          <h2 style={{ marginTop: hasCategory ? 0 : "auto", fontWeight: 400 }}>
            {hasCategory
              ? `${titlePrefix} ${categoryObj.data.category} Articles`
              : "All Articles"}
          </h2>
          {articles.map((article, i) => {
            if (!exclude || article.id !== exclude) {
              return (
                <Box
                  key={i}
                  sx={{
                    mt: 2,
                    "& a": {
                      color: theme.palette.primary.main,
                      "&:visited": {
                        color: theme.palette.secondary.dark,
                      },
                    },
                  }}
                >
                  <Typography>
                    <Link href={`/article/${article.uid}`}>
                      <span>{RichText.asText(article.data.title)}</span>
                    </Link>
                  </Typography>
                  <div
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: "12px",
                    }}
                  >
                    Published in{" "}
                    {article.data.category.data
                      ? renderCategory(article.data.category)
                      : "Unclassified"}
                  </div>
                </Box>
              )
            }
            return null
          })}
        </Box>
      </RoundedCard>
    </Grid>
  )
}

export default Articles
